import { LoadingOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import invoice from '../../assets/images/wenslogo.png'

const LoaderBox = ({ loader, noData, style }) => (
  <>
    {loader && (
      <div className="loading-content" style={style}>
        <img src={invoice} alt="Loading" />
        <h3>
          {loader === true ? (
            <div>
              <LoadingOutlined loop /> Loading..
            </div>
          ) : (
            loader
          )}
        </h3>
      </div>
    )}
    {!loader && noData && (
      <div className="loading-content" style={style}>
        <img src={invoice} alt="No Data found" />
        <h1>{noData === true ? 'No Data found..' : noData}</h1>
      </div>
    )}
  </>
)

LoaderBox.propTypes = {
  loader: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  noData: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  style: PropTypes.object
}

export default LoaderBox
